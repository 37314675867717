const firebaseConfig = {
  apiKey: "AIzaSyBLaxWf09edPHVGkmi1DUmLa14d2i5iHqc",
  authDomain: "midtermweb3-b31e4.firebaseapp.com",
  projectId: "midtermweb3-b31e4",
  storageBucket: "midtermweb3-b31e4.appspot.com",
  messagingSenderId: "688922622326",
  appId: "1:688922622326:web:1d3eebf71f04d0d8f5a257",
  measurementId: "G-FBJ6BC4GV9"
};

export default firebaseConfig;